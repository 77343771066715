.add-note-button {
    background-color: #7f9446;
    color: beige;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
}

.add-note-button:hover {
    background-color: #879dd3;
}