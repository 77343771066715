.control{
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
   

}
.form-control{
   background-color: #788660;
    color:#383C1A;
    
}

.form-control input[type="email"] {
    background-color: beige;

}

.form-control input[type="password"] {
    background-color: beige;

}

.form-control input[type="text"] {
    background-color: beige;

}
.image{
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 30%;
    padding-bottom: 30%;
    
}
.container{
    width: 100%;
    background-image: linear-gradient(180deg,#e0e0de,#788660);
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 40%;
    padding-left: 7%;
    padding-right: 7%;
    
}
.google-login-button {
    display: flex;
    justify-content: center;
}
.google-login-container {
    display: flex;
    justify-content: center;
}