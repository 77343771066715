.note-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.note-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    z-index: 1001;
}

.note-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.note-title-input,
.note-content-textarea {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.note-content-textarea {
    height: 100px;
    resize: none;
}

.save-button {
    background-color: #7f9446;
    color: beige;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #879dd3;
}