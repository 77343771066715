.note-bar-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.note-bar-item {
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

.note-bar-item:hover {
  background-color: #f9f9f9;
}

.note-timestamp {
  display: block;
  font-size: 0.8em;
  color: gray;
}

.note-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note-content {
  display: flex;
  flex-direction: column;
}

.note-bar-delete {
  margin-left: auto;
  border-radius: 5px;
  color: tomato;
}