.hero-section {
    height: 100vh; /*Full height of the viewport */
    width: 100%; /* Full width */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    background-size: cover; /* Cover the entire div with the image */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    position: relative;
    background-image: linear-gradient(180deg,#e0e0de,#788660);
    text-align: center;
    
  }
  
  .hero-text {
    color: white; /* Ensuring text is visible on darker backgrounds */
    font-size: 2.5em;
    z-index: 2;
    text-align: center;
    margin-bottom: 20px; /* Spacing above the buttons or end of section */
  }
  
  .hero-buttons {
    z-index: 2;
   display: flex;
    gap: 20px;
    padding: 10px;
    justify-content: center;
    align-items:  flex-start;
   
  }
  .login-button{
    background-color: #788660;
    color: #e0e0de;
    border-radius: 10px;
  }
  .signup-button{
    background-image: linear-gradient(90deg,#5170ff,#ff66c4);
    color: white;
  }
  .hero-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
    object-fit: cover; /* Cover the area without distorting aspect ratio */
    margin: 10%;
  }
  .logo-container{
    z-index: 2;
    display:block;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  
  }
  .joke{
  background-color: #e0e0de;
  text-align: center;
  display: flex;
  margin: 10%;
  padding: 3%;
  color: #383C1A;
  border-color: #383C1A;
  border-radius: 10px;
  border: 1px solid;
  }
  .catpic{
    width: 250px;
  height: 250px;
  object-fit: cover;
  background-color:  #e0e0de;
  color: #383C1A;
 
  
  
  }
/* This will make the divs align horizontally by default */
.parent-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px; 
}

/* This will make the divs align vertically on screens smaller than 768px */
@media (max-width: 768px) {
  .parent-div {
    flex-direction: column;
  }
}
.joke, .hero-buttons, .catpic {
  margin-bottom: 20px;
  flex: 1;
  margin: 10px;
}
@media (max-width: 768px) { /* Adjust 768px to the breakpoint you want */
  .logo-container {
    
    z-index: 3; /* Ensure the logo is above the joke div */
  }

  .joke {
    position: relative;
    z-index: 2; /* Ensure the joke div is below the logo */
  }
}