/* vanAi.css */
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}
.vanAi-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    transition: margin-left 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
    background-color: beige;
    
}
.vanAi-container.sidebar-open {
    margin-left: 5px;

}
.vanAi-content {
    flex: 2;
    overflow-y: auto;
    padding: 40px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: beige;
    align-items: center;
   
}
.conversation-container {
    
    flex:1;
    flex-grow: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding:0;
    background-color: beige;
    border-radius: 5px;
    
}
.card-container{
    background-image: none;
}

@media (max-width: 768px) {
    .input-group {
        flex-direction: row;
       
    }
    
    .vanAi-container.sidebar-open {
        margin-left: 0;
    }
    
    .conversation-sidebar {
        width: 100%;
        left: -100%;
    }
    
    .conversation-sidebar.show {
        left: 0;
    }
    .vanAi-container.sidebar-open {
        margin-left: 80%; /* Adjust content position when sidebar is open */
    }
  
    .bot-message, .assistant-message {
        padding: 10px;
        margin: 5px 0;
        width: 100%;
        box-sizing: border-box;
        border-radius: 20px;
    }
    .user-message {
       
        background-color: #7f9446;
        padding: 10px;
        margin: 5px 0;
        border-radius: 20px;
     
        
    }
    .nextSuggestions-container {
        width: 100%;
        padding: 10px;
        z-index: 300;
      }
    
      .suggestions {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 310; /* Ensure higher z-index */
    }
    
}
/* Mobile view adjustments */
@media (max-width: 768px) {
    .vanAi-container {
        overflow-x: hidden; /* Prevent horizontal scrolling */
        
    }
    .conversation-container {
        overflow-y: auto; /* Add this line */
    }
    .conversation-sidebar {
        z-index: 1000; /* Ensure sidebar is on top */
        transform: translateX(-100%); /* Initially hidden */
        transition: transform 0.3s ease-in-out;
        width: 80%; /* Full width for mobile */
    }

    .conversation-sidebar.show {
        transform: translateX(0); /* Show sidebar */
    }

    .vanAi-content {
        position: relative;
        z-index: 100; /* Make sure content is below sidebar */
        flex-grow: 1;
    }
    .message-container {
        display: flex;
        align-items: center;
        justify-content: right;
        width: 100%;
        padding: 10px;
    }
  
   .note-modal{
    z-index: 1001;
   }
    /* Sidebar toggle button on mobile */
    .toggle-sidebar {
        z-index: 110; /* Above the sidebar */
    }
}

/* Desktop view adjustments */
@media (min-width: 769px) {
    .conversation-sidebar {
        z-index: 100; /* Ensure sidebar is on top */
        transform: translateX(-100%); /* Initially hidden */
        width: 20%; /* Sidebar width */
        transition: transform 0.3s ease-in-out;
    }

    .conversation-sidebar.show {
        transform: translateX(0); /* Show sidebar */
    }

    .vanAi-container.sidebar-open .vanAi-content {
        margin-left: 290px; /* Adjust content position when sidebar is open */
    }
  
    
    .vanAi-content{
        width: 80%;
        transition: margin-left 0.3s ease-in-out;
    }
    .input-container{
        width: 80%;
        left: 10%;
        transition: margin-left 0.3s ease-in-out;
    }
    .vanAi-container.sidebar-open .input-container {
        margin-left: 150px; /* Adjust content position when sidebar is open */
    }
}

@media (max-width: 768px) {
    .code-block-container {
        margin: 1em -10px; /* Negative margin to counteract padding */
    }

    pre {
        border-radius: 0;
        padding: 10px 5px;
        
    }

    .copy-code-button {
        top: 2px;
        right: 2px;
        padding: 2px 5px;
        font-size: 0.8em;
    }
    code {
        max-width: 100%;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }
}
/* Ensure that the highlight.js styles are applied */

.notes-ui-container{
    z-index: 1000;
}
