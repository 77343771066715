/* src/components/SuggestionCard.css */
.suggestion-card {
    
    cursor: pointer;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.2s;
    border-radius: 20px;
    border: none;
    background-color: beige;
}

.suggestion-card:hover {
    transform: scale(1.05);
    background-color: #879dd3;
}
