.file-input-label {
    background-color: #28a745 ;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 70px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    text-align: center;
    gap: 10px;
}
.file-input-label:hover {
    background-color: #879dd3;
}
.file-text-button{
    background-color: beige;
    padding-bottom: 5px;
    padding-left: 2px;
    padding-right: 2px;
    justify-content: center; 
    border-radius: 10px 10px 0 0;
    width: 100%;
}
.plus-button{
    border-radius: 50px;
    padding-right: 2px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;

}

.image-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  
    

}
.control-image{
    justify-content: center;
    display: flex;
    border-radius: 20px;
 }
.image-preview img {
    border-radius: 20px;
}
.image-preview{
    padding-right: 48%;
    padding-left: 5%;
    background-color: beige;
    border-radius: 20px 20px 0 0;
    
}
.image-itself{
    padding-right: 10px;
    background-color: beige;
    border-top-left-radius: 20px;
    
    padding-left: 5px;
}
.remove-image {
    background-color: #dc3545;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
}
.remove-image:hover {
    background-color: #c82333;
}

/* text input */
.input-group input[type="text"] {
    flex: 1;    
    color: #000000;
    background-color: beige;
    border: none;
    
}
.input-field {
    flex-grow: 1;
    font-size: 16px;
    resize: vertical;
    overflow: hidden;
    background-color:rgb(255, 255, 156);
    border-color: beige;
    border-radius: 10px;
    width: 100%;
  
    
}

.text-area{
    
    flex-grow: 1;
    justify-content: center;
    overflow-y: hidden;
    min-height: 36px;
    max-height: 200px;
    overflow-y: auto;
    width: 60%;
    border-bottom: 1px solid #ced4da;
    
}
/* submit button */
.submit-button{
    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background-color: #28a745 !important;
    border-radius: 40px !important;
    color: white;
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
  
    white-space: nowrap !important; /* Prevents button text from wrapping */
}
.submit-button:hover {
    background-color: #879dd3 !important;
}
.button-control{
    position: relative;
    bottom: 0;
   
    
}
/* input field */

.input-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 80%;
    background-color:transparent;
    border: none;
    border-radius: 10px;
    z-index: 998;
   
}

@media (max-width: 768px) {
    .input-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
       
    }
}

.input-group {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    width:100%;
    justify-content: center;
    overflow-y: hidden;
}