/* NotesUI.css */
.note-box{
  padding: 10px;
}
.notes-ui {
    position: fixed;
    width: 16rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    overflow: auto;
    user-select: none;
    z-index: 1000;
    padding-bottom: 2rem;
  }
  
  .notes-ui.dragging {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .notes-ui-header {
    height: 2rem;
    background-color: #f3f4f6;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    cursor: move;
    position: relative; /* For absolute positioning of close button */
  }
  
  .notes-ui-handle-bar {
    width: 7rem;
    height: 1rem;
    background-color: #d1d5db;
    border-radius: 9999px;
    margin: 0 auto; /* Center the handle bar */
  }
  
  .notes-ui-close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s;
    color: #a62400;
    border: none;
    background: none;
    font-size: 1.25rem;
    padding: 0;
  }
  
  .notes-ui-close:hover {
    background-color: #e5e7eb;
    color: #374151;
  }
  
  .notes-ui-content {
    padding: 1rem;
    position: relative;
  }
  
  .notes-ui-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #1f2937;
  }
  
  .notes-ui-text {
    color: #4b5563;
    white-space: pre-wrap;
  }

  .edit-button {
    position: absolute;
    bottom: 0.5rem;
    right:0.5rem;
  }

  .notes-ui-content-textarea{
    overflow: auto;
    width: 100%; 
    height: 300px;
  }